/* Global */
.flex_div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
/* Global */

.new_contract_view {
  width: 85%;
  margin: 0 auto;
  display: grid;
  overflow: hidden;
  grid-template-columns: 1fr;
  grid-template-areas:
    'title_nc'
    'complete_info_contract'
    'authorizers_nc'
    'signers_nc'
    'recipients_nc'
    'send_contract_nc';

  gap: 10px 0;

  .title_nc {
    grid-area: title_nc;
  }

  .complete_info_contract {
    grid-area: complete_info_contract;
  }

  .authorizers_nc {
    grid-area: authorizers_nc;
  }

  .signers_nc {
    grid-area: signers_nc;
  }

  .recipients_nc {
    grid-area: recipients_nc;
  }

  .send_contract_nc {
    grid-area: send_contract_nc;
    text-align: right;
  }
}

/* COMPLETE INFO CONTRACT IN NEW CONTRACT */

.complete_info_contract {
  display: grid;
  gap: 0 10px;

  &.padded {
    padding: 10px 20px;
  }

  .name_nc {
    grid-area: name_nc;
  }

  .dropzone_nc {
    grid-area: dropzone_nc;
  }
  .description_nc {
    grid-area: description_nc;
  }

  .expires_nc {
    grid-area: expires_nc;
    .expiresOn {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .switch_expires {
        width: 100%;
        margin-top: 5px;
      }
    }
  }

  .labels_nc {
    grid-area: labels_nc;
    .labels_flex {
      display: flex;
      align-items: center;
      justify-content: space-between;
      div {
        width: 100%;
      }
    }
  }

  .list_labels_nc {
    grid-area: list_labels_nc;
    overflow-x: auto;
    white-space: nowrap;
  }
}

/* AUTHORIZERS NEW CONTRACT */
.authorizers_nc {
  /* PADDING VERTIXAL HORIZONTAL */
  &.padded {
    padding: 10px 20px;
  }

  /* SWITCH AUTHORIZERS */
  .switch_nc_authorizers {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 10px;

    label {
      margin-right: 5px;
    }
  }

  /* AUTHORIZERS LIST */

  .container_config_authorizers {
    display: grid;
    // grid-template-columns: 30% 70%;
  }

  .list_authorizers_nc {
    .container_inputs_authorizer {
      display: grid;

      .inputs_auth {
        grid-area: inputs_auth;
        display: grid;
        .legalRfc_auth_nc {
          grid-area: legalRfc_auth_nc;
          display: flex;
          align-items: center;
          small {
            display: none;
          }
        }

        .legalName_auth_nc {
          grid-area: legalName_auth_nc;
        }

        .email_auth_nc {
          grid-area: email_auth_nc;
        }
      }

      .action_delete_nc {
        grid-area: action_delete_nc;
        display: flex;
        align-items: center;
        justify-content: space-between;
        small {
          font-size: 16px;
        }
      }
    }
  }
}

/* SIGNERS NEW CONTRACT */
.signers_nc {
  &.padded {
    padding: 10px 20px;
  }

  .choose_signature_warning {
    display: grid;

    .warning {
      background: #ffdb83;
      margin: 6px 0;
      padding: 11px;
      color: #555;
      border-radius: 4px;
      font-size: 14px;

      i {
        padding-left: 10px;
      }
    }
  }

  .dropdown_choose_signature {
    position: relative;
    // margin-right: 10px;
    i {
      position: absolute;
      min-width: 20px;
      padding: 17px 0;
      right: 0;
      top: 0;
    }
  }

  .container_config_signers {
    display: grid;
    grid-template-columns: 30% 70%;

    .switches_signers {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: flex-end;

      .switch_signer {
        color: #6c6c6c;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .signers_list {
    .container_inputs_signers {
      display: grid;
      margin-top: 15px;
      .inputs_signers {
        display: grid;
        grid-area: inputs_signers;
        .legalRfc_signer_nc {
          grid-area: legalRfc_signer_nc;
          small {
            display: none;
          }
        }

        .legalName_signer_nc {
          grid-area: legalName_signer_nc;
        }

        .email_signer_nc {
          grid-area: email_signer_nc;
        }

        .select_signature_active {
          grid-area: select_signature_active;
          position: relative;
          small {
            display: none;
          }
          i {
            position: absolute;
            min-width: 20px;
            padding: 18px 0;
            right: 0;
            top: 0;
          }
        }
      }

      .action_delete_signer_nc {
        grid-area: action_delete_signer_nc;
        display: flex;
        align-items: center;
        justify-content: space-between;
        small {
          font-size: 16px;
        }
      }
    }
  }
}

.recipients_nc {
  &.padded {
    padding: 10px 20px;
  }

  .container_config_recipients {
    display: grid;
    // grid-template-columns: 30% 70%;
  }

  .list_recipients_nc {
    .container_inputs_recipients {
      display: grid;

      .inputs_recipients {
        grid-area: inputs_recipients;
        display: grid;
        .legalRfc_recipient_nc {
          grid-area: legalRfc_recipient_nc;
          small {
            display: none;
          }
        }

        .legalName_recipient_nc {
          grid-area: legalName_recipient_nc;
        }

        .email_recipient_nc {
          grid-area: email_recipient_nc;
        }
      }

      .action_delete_recipient_nc {
        grid-area: action_delete_recipient_nc;
        display: flex;
        align-items: center;
        justify-content: space-between;
        small {
          font-size: 16px;
        }
      }
    }
  }
}

/* SELECT IDENTITY DOCUMENTS NC */
.add_document_nc {
  color: blue;
  font-size: 16px;
  margin-bottom: 5px;
  display: grid;
  cursor: pointer;
  .title_signers {
    grid-area: title_signers;
    padding: 10px 0px;
    // padding-right: 5px;
    // border-right: 1.5px solid #dee0e3;
  }

  .user_documents {
    grid-area: user_documents;
    display: flex;
    align-items: center;
    // margin-left: 5px;
    overflow-x: auto;
    white-space: nowrap;
    span {
      font-size: 11px;
      color: #666a75;
      background: @color-white;
      padding: 5px;
      border-radius: 13px;
      border: 1px solid #666a75;
      margin-right: 5px;
      margin-top: 2px;
      margin-bottom: 5px;
    }
  }
}

.choose_identity_docs_nc {
  .header_identity_modal {
    text-align: center;
  }
  .list_checkbox_docs {
    display: grid;
    align-items: center;
    margin: 0 auto;

    .switchs_identity_docs {
      display: grid;
      align-items: center;
      margin-top: 10px;

      .switch_div_identity {
        text-align: right;
      }

      .doc_name_identity {
        text-align: right;
      }
    }
  }

  .input_identity_doc_modal {
    margin-bottom: 10px;
    .message_input_doc_modal {
      display: flex;
      align-items: center;
      margin-top: 30px;
      small {
        text-align: left;
      }
      p {
        margin: 0;
      }
    }
  }
}

.checkbox_nc {
  .checkbox_verification_signer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    overflow-x: auto;
    white-space: nowrap;
    .verifyCreateSign,
    .requestVerification {
      display: flex;
      align-items: flex-start;
      font-size: 14px;
      color: #6c6c6c;
      margin-top: 5px;
      max-width: 50px;
      .input_checkbox {
        padding: 1px 0;
        padding-right: 5px;
        margin-bottom: 5px;
      }
      .tooltip_checkbox {
        padding: 1px 0;

        span {
          text-overflow: ellipsis;
        }
      }
    }
  }
}

/* MEDIA QUERIES */

/* Extra small devices (phones, 600px and down) */
@media (max-width: 600px) {
  /* .new_contract_view {
    font-size: 13px !important;

    input {
      font-size: 13px !important;
    }
  } */
  .complete_info_contract {
    grid-template-columns: 1fr;
    grid-template-areas:
      'dropzone_nc'
      'name_nc '
      'description_nc'
      'expires_nc'
      'labels_nc'
      'list_labels_nc';

    .expires_nc {
      .expiresOn {
        flex-direction: column;
      }
    }

    .labels_nc {
      .labels_flex {
        flex-direction: column;
      }
    }
  }

  .container_config_authorizers {
    grid-template-columns: 1fr;
  }

  /* AUTHORIZERS LIST */
  .container_inputs_authorizer {
    grid-template-columns: 1fr;
    grid-template-areas:
      'action_delete_nc'
      'inputs_auth';

    .inputs_auth {
      grid-template-columns: 1fr;
      grid-template-areas:
        'legalRfc_auth_nc'
        'legalName_auth_nc'
        'email_auth_nc';
    }
  }

  /* SIGNERS NC CONFIG*/
  .signers_nc {
    /* SELECT SIGNATURE SIGNERS */
    .choose_signature_warning {
      grid-template-columns: 1fr;
    }

    .container_config_signers {
      grid-template-columns: 1fr;

      .container_search_signer {
        margin-bottom: 10px;
      }

      .switches_signers {
        justify-content: flex-start;
        .switch_signer {
          padding-bottom: 10px;
          span {
            padding-left: 10px;
          }
        }
      }
    }
  }

  /* SIGNERS LIST */
  .container_inputs_signers {
    grid-template-columns: 1fr;
    grid-template-areas:
      'action_delete_signer_nc'
      'inputs_signers';

    .inputs_signers {
      grid-template-columns: 1fr;
      grid-template-areas:
        'legalRfc_signer_nc'
        'legalName_signer_nc'
        'email_signer_nc';
      &.dropdown_active {
        grid-template-areas:
          'select_signature_active'
          'legalName_signer_nc'
          'email_signer_nc'
          'legalRfc_signer_nc';
      }
    }
  }

  /* RECIPIENTS LIST */
  .container_config_recipients {
    grid-template-columns: 1fr;
  }
  .container_inputs_recipients {
    grid-template-columns: 1fr;
    grid-template-areas:
      'action_delete_recipient_nc'
      'inputs_recipients';

    .inputs_recipients {
      grid-template-columns: 1fr;
      grid-template-areas:
        'legalRfc_recipient_nc'
        'legalName_recipient_nc'
        'email_recipient_nc';
    }
  }

  .add_document_nc {
    grid-template-columns: 1fr;
    grid-template-areas:
      'title_signers'
      'user_documents';
  }

  .choose_identity_docs_nc {
    .list_checkbox_docs {
      display: grid;
      grid-template-columns: 1fr;
      align-items: center;
      gap: 0 10px;
      margin: 0 auto;

      .switchs_identity_docs {
        display: grid;
        grid-template-columns: 80% 20%;
        align-items: center;
      }
    }
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media (min-width: 600px) {
  /* .new_contract_view {
    font-size: 14px !important;

    input {
      font-size: 14px !important;
    }
  } */
  .complete_info_contract {
    grid-template-columns: 1fr;
    grid-template-areas:
      'dropzone_nc'
      'name_nc '
      'description_nc'
      'expires_nc'
      'labels_nc'
      'list_labels_nc';

    .expires_nc {
      .expiresOn {
        flex-direction: row;
      }
    }

    .labels_nc {
      .labels_flex {
        flex-direction: column;
      }
    }
  }

  .container_config_authorizers {
    grid-template-columns: 1fr;
  }

  /* AUTHORIZERS LIST */
  .container_inputs_authorizer {
    grid-template-columns: 1fr;
    grid-template-areas:
      'action_delete_nc'
      'inputs_auth';

    .inputs_auth {
      grid-template-columns: 1fr;
      grid-template-areas:
        'legalRfc_auth_nc'
        'legalName_auth_nc'
        'email_auth_nc';
    }
  }

  /* SIGNER NC */
  .signers_nc {
    /* SELECT SIGNATURE SIGNERS */
    .choose_signature_warning {
      grid-template-columns: 1fr;
    }

    .container_config_signers {
      grid-template-columns: 1fr;

      .container_search_signer {
        margin-bottom: 10px;
      }

      .switches_signers {
        justify-content: flex-start;
        .switch_signer {
          padding-bottom: 10px;
          span {
            padding-left: 10px;
          }
        }
      }
    }
  }

  /* SIGNERS LIST */
  .container_inputs_signers {
    grid-template-columns: 1fr;
    grid-template-areas:
      'action_delete_signer_nc'
      'inputs_signers';

    .inputs_signers {
      grid-template-columns: 1fr;
      grid-template-areas:
        'legalRfc_signer_nc'
        'legalName_signer_nc'
        'email_signer_nc';
      &.dropdown_active {
        grid-template-areas:
          'select_signature_active'
          'legalName_signer_nc'
          'email_signer_nc'
          'legalRfc_signer_nc';
      }
    }
  }

  /* RECIPIENTS LIST */
  .container_config_recipients {
    grid-template-columns: 1fr;
  }
  .container_inputs_recipients {
    grid-template-columns: 1fr;
    grid-template-areas:
      'action_delete_recipient_nc'
      'inputs_recipients';

    .inputs_recipients {
      grid-template-columns: 1fr;
      grid-template-areas:
        'legalRfc_recipient_nc'
        'legalName_recipient_nc'
        'email_recipient_nc';
    }
  }

  .add_document_nc {
    grid-template-columns: 105px auto;
    grid-template-areas: 'title_signers user_documents';
  }

  .choose_identity_docs_nc {
    .list_checkbox_docs {
      display: grid;
      grid-template-columns: 1fr;
      align-items: center;
      gap: 0 10px;
      margin: 0 auto;

      .switchs_identity_docs {
        display: grid;
        grid-template-columns: 80% 20%;
        align-items: center;
      }
    }
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media (min-width: 768px) {
  .complete_info_contract {
    .labels_nc {
      .labels_flex {
        flex-direction: row;
      }
    }
  }

  .choose_identity_docs_nc {
    .list_checkbox_docs {
      display: grid;
      grid-template-columns: 1fr 1fr;
      align-items: center;
      gap: 0 10px;
      margin: 0 auto;

      .switchs_identity_docs {
        display: grid;
        grid-template-columns: 80% 20%;
        align-items: center;
      }
    }
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  /* .new_contract_view {
    font-size: 15px !important;

    input {
      font-size: 15px !important;
    }
  } */
  .complete_info_contract {
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      'name_nc dropzone_nc'
      'description_nc dropzone_nc '
      'expires_nc labels_nc'
      '. list_labels_nc';
  }

  .container_config_authorizers {
    grid-template-columns: 30% 70%;
  }

  .container_inputs_authorizer {
    grid-template-columns: 1fr;
    grid-template-areas: 'inputs_auth action_delete_nc';
    gap: 0 10px;

    .inputs_auth {
      grid-template-columns: repeat(3, 1fr);
      grid-template-areas: 'legalRfc_auth_nc legalName_auth_nc email_auth_nc';
      gap: 0 20px;

      .legalRfc_auth_nc {
        small {
          color: #6c6c6c;
          display: block !important;
          align-items: center;
          padding-right: 5px;
          font-size: 16px;
        }
      }
    }

    .action_delete_nc {
      justify-content: right;
      small {
        display: none;
      }
    }
  }

  /* SIGNER NC */
  .signers_nc {
    /* SELECT SIGNATURE SIGNERS */
    .choose_signature_warning {
      grid-template-columns: 30% 70%;
    }

    .container_config_signers {
      grid-template-columns: 30% 70%;

      .switches_signers {
        justify-content: flex-end;
      }
    }
  }

  /* SIGNERS LIST */
  .container_inputs_signers {
    grid-template-columns: 1fr;
    grid-template-areas: 'inputs_signers action_delete_signer_nc';
    gap: 0 10px;

    .inputs_signers {
      grid-template-columns: repeat(3, 1fr);
      grid-template-areas: 'legalRfc_signer_nc legalName_signer_nc email_signer_nc';
      gap: 0 20px;
      &.dropdown_active {
        grid-template-columns: repeat(4, 1fr);
        grid-template-areas: 'select_signature_active legalName_signer_nc email_signer_nc legalRfc_signer_nc';
      }

      .legalRfc_signer_nc,
      .select_signature_active {
        display: flex;
        align-items: center;
        small {
          color: #6c6c6c;
          display: block !important;
          align-items: center;
          padding-right: 5px;
          font-size: 16px;
        }
      }
    }

    .action_delete_signer_nc {
      justify-content: right;
      small {
        display: none;
      }
    }
  }

  /* RECIPIENTS LIST */
  .container_config_recipients {
    grid-template-columns: 30% 70%;
  }
  .container_inputs_recipients {
    grid-template-columns: 1fr;
    grid-template-areas: 'inputs_recipients action_delete_recipient_nc';
    gap: 0 10px;

    .inputs_recipients {
      grid-template-columns: repeat(3, 1fr);
      grid-template-areas: 'legalRfc_recipient_nc legalName_recipient_nc email_recipient_nc';
      gap: 0 20px;

      .legalRfc_recipient_nc {
        display: flex;
        align-items: center;
        small {
          display: block !important;
          color: #6c6c6c;
          align-items: center;
          padding-right: 5px;
          font-size: 16px;
        }
      }
    }

    .action_delete_recipient_nc {
      justify-content: right;
      small {
        display: none;
      }
    }
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .container_inputs_authorizer {
    .inputs_auth {
      gap: 0 50px;
    }
  }
}

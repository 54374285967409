@import 'lib/normalize.less';
@import 'lib/notifications.css';
@import 'lib/animate.css';
@import 'lib/wysiwyg.css';
@import 'grid.less';
@import 'variables.less';
@import 'dashboard.less';
@import 'loader.less';
@import './Contracts/listContracts.less';
@import './Contracts/contractDetail.less';
@import './Profile/profile.less';
@import './NewContract/newContract.less';
@import './NewContract/newContractMassive.less';
@import './Landing/login.less';
@import './Admins/admins.less';
@import './Landing/publicInfo.less';
@import './Profile/superUser.less';

body {
  background-color: @color-backdrop;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
}

.flex {
  display: flex;

  &.flex-end {
    justify-content: flex-end;
  }
}

/**
 * Typography
 */

h1 {
  font-weight: 500;
  color: @color-dark;
}

a {
  text-decoration: none;
  color: @color-primary;
}

.label_nc {
  display: block;
  color: @color-gray;
  padding-top: 10px;
  width: 100%;
  box-sizing: border-box;
}
.input_nc {
  border: 1pt solid darken(@color-backdrop, 5%);
  border-radius: 4pt;
  padding: 8pt;
  outline: none;
  font-size: 11pt;
  font-weight: 400;
  margin: 6px 0;
  width: 100%;
  box-sizing: border-box;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  transition-duration: 200ms;
  -webkit-transition-duration: 200ms;

  &.my_template_input {
    margin-right: 10px;
    border: none;
  }

  &.search_signer {
    padding-left: 30px;
  }
  &.date {
    width: 98%;
  }

  &:active:not(:disabled),
  &:focus:not(:disabled) {
    border-color: lighten(@color-primary, 20%);
    transition-duration: 200ms;
    -webkit-transition-duration: 200ms;
  }
}

/**
 * Forms
 */

form {
  label {
    display: block;
    color: @color-gray;
  }
  input,
  textarea,
  select,
  label[for='myfile'] {
    border: 1pt solid darken(@color-backdrop, 5%);
    border-radius: 4pt;
    padding: 8pt;
    outline: none;
    font-size: 11pt;
    font-weight: 400;
    margin: 6px 0;
    width: 100%;
    box-sizing: border-box;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: transparent;
    transition-duration: 200ms;
    -webkit-transition-duration: 200ms;

    &:active:not(:disabled),
    &:focus:not(:disabled) {
      border-color: lighten(@color-primary, 20%);
      transition-duration: 200ms;
      -webkit-transition-duration: 200ms;
    }
  }

  input[type='file'] {
    display: none;
  }

  .image_profile {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }

  textarea {
    line-height: 15pt;
    max-width: 100%;
    min-width: 100%;
    min-height: 60pt;
  }
}

#cbox2 {
  min-width: 15px;
  min-height: 15px;
  -webkit-appearance: auto;
  -moz-appearance: auto;
}

/* ::-webkit-scrollbar {
  width: 8px;
  height: 3px;
}

::-webkit-scrollbar-track {
  // box-shadow: inset 0 0 5px #fff;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #dfdede;
  border-radius: 10px;
  margin-top: 5px;
} */

.scrollable {
  max-height: 55vh;
  overflow: auto;
}

.scrollable_sidebar {
  // max-height: 60vh;
  border-bottom: 1px solid white;
  overflow: auto;
}

.scrollable_sidebar::-webkit-scrollbar {
  display: none;
}

/* ESTO ES NUEVO DE RESPONSIVO */

thead,
tbody,
tr {
  display: contents;
}

th,
td {
  padding: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: @color-dark;
  border-bottom: 1px solid lighten(@color-gray, 52%);
}

tr {
  &.pending {
    background-color: @color-pending;
  }

  &.waiting-others {
    background-color: @color-waiting-others;
  }

  &.unread {
    font-weight: bold;
  }
}

th {
  position: static;
  top: 0;
  background: transparent;
  text-align: left;
  font-weight: 500;
  font-size: 1.1rem;
  color: lighten(@color-gray, 30%);
  position: relative;
}

.center_loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding-top: 50px;
  margin-bottom: 50px;
}

#tooltip_modal {
  display: none;
  position: fixed;
  background-color: #ffdb83;
  color: black;
  font-size: 15px;
  border-radius: 3px;
  padding: 10px;
  max-width: 350px;

  .tooltip_title {
    font-weight: 500;
    line-height: 1.5px;
  }

  .tooltip_info {
    color: #525252;
  }
}

.modal-container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  word-wrap: break-word;
  z-index: 999;

  .modal {
    position: relative;
    z-index: 999;
    background-color: @color-white;
    border-radius: 5px;
    // overflow-y: auto;
    padding: 20px 20px;

    &.contract_preview {
      padding: 0;
    }

    &.verification_modal {
      text-align: center;
    }

    h1 {
      color: rgb(208, 2, 27);
      text-align: left;
    }

    /* STYLES MODAL TO DOWNLOAD CONTRACTS PDF AND EXCEL */
    .selected_date_modal {
      width: 90%;
      height: 90%;
      margin: 0 auto;
      text-align: center;

      .dropdonw_download_pdf {
        width: 100%;
        padding: 10px 0;
        background: @color-white;
        border: 1px solid #d2d2d2 !important;
        border-radius: 4px;
        margin-top: 10px;
        font-size: 15px;
      }
      .choose_input_date {
        margin: 15px 0;

        .div_input_date {
          width: 100%;
          input {
            padding: 15px 5px;
            margin: 0;
            width: 99%;
            margin-top: 10px;
          }
        }
      }

      .see_total_contracts {
        text-align: center;
        .see_total_ask {
          color: @color-primary;
        }
      }

      .warning_download_pdfs {
        margin: 30px 0;
        text-align: center;
      }
    }

    /* STYLES MODAL TO SIGN CONTRACTS IN VIEW CONTRACTS */
    .info_sign_documents_modal {
      text-align: center;
      height: 90%;
    }

    .sign_contract_modal {
      width: 90%;
      height: 95%;
      margin: 0 auto;
      text-align: center;

      .body_modal {
        margin-top: 50px;

        .warning_save_key {
          margin-bottom: 30px;
        }
      }
    }

    /* GENERA STYLES MODAL */
    .body_modal {
      text-align: justify;
    }

    .footer_modal {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      position: sticky;
      top: 100vh;
    }

    .body_modal {
      .verification_type_id {
        width: 30%;
        margin: 10px;
        padding: 20px;
        border-radius: 12px;
        display: flex;
        flex-direction: column;
        align-items: center;

        &.select_active_id {
          box-shadow: 2px 2px 9px -2px rgba(0, 0, 0, 0.75);
          -webkit-box-shadow: 2px 2px 9px -2px rgba(0, 0, 0, 0.75);
          -moz-box-shadow: 2px 2px 9px -2px rgba(0, 0, 0, 0.75);
          border: 2px solid @color-primary;
          color: @color-primary;
        }

        &.not_active_id {
          border: 1px solid #6c6c6c;
        }
      }

      .container_steps_verification {
        .button_open_camera {
          text-align: center;

          .capture_photo {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            margin: 0 auto;
            border: 1px solid @color-primary;
            border-radius: 6px;
            max-width: 150px;
            cursor: pointer;
            padding: 10px;

            i {
              padding-right: 10px;
              color: @color-primary;
            }

            &:hover {
              background: #e7e6e6;
              transition: 0.3s ease-in;
            }
          }
        }
      }
    }
  }

  /* .background-screen {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.4);
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  } */

  iframe {
    position: relative;
    z-index: 10;
    width: 100%;
    margin: 15px auto 0;
    display: block;
    height: 70vh;
  }

  .cover_camera {
    inset: 0px;
    width: 100%;
    height: 100%;
    display: flex;
    z-index: 12;
    overflow: hidden;
    position: fixed;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background-color: black;

    .vid_container {
      display: block;
      overflow: hidden;
      position: relative;
      max-width: 100%;
      max-height: 100%;
      background-color: rgb(0, 0, 0);

      video {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 5px;
      }

      .flip_image {
        top: 1rem;
        right: 1rem;
        position: absolute;
        color: whitesmoke;
        cursor: pointer;
        i {
          font-size: 22px;
        }
      }
    }

    .flex_capture_photo {
      width: 100%;
      bottom: 0px;
      height: 5rem;
      display: flex;
      justify-content: space-around;
      color: @color-white;
      font-size: 22px;
      margin-top: 20px;
      cursor: pointer;
    }
  }
}

/* MODAL SIGN */
.modal-container-sign {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  word-wrap: break-word;
  z-index: 999;

  .modal-sign {
    position: relative;
    z-index: 999;
    background-color: @color-white;
    border-radius: 5px;
    overflow: auto;

    .react-pdf__Document {
      height: 100vh;
      overflow-x: hidden;
    }

    .react-pdf__Page {
      margin: 10px;
      background-color: @color-white;
      border: 1px solid darkgrey;
      // margin-left: 5px;
      border-radius: 4px;
    }

    object,
    iframe {
      overflow: auto;
      object-fit: cover;
      position: relative;
      z-index: 10;
      // max-height: 70vh;
      margin: 15px auto 0;
      display: block;
    }

    .body_modal {
      text-align: justify;

      .participants_list {
        display: grid;

        small {
          font-size: 12px;
          font-weight: 400;
          color: #6c6c6c;
          margin-bottom: 0;
        }

        ul {
          font-size: 12px;
          font-weight: 400;
          color: #6c6c6c;
          margin-left: 0;
          margin-top: 0;
          text-align: left;
        }
      }
    }

    h1 {
      color: rgb(208, 2, 27);
    }

    .footer_modal {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      padding: 0 10px;

      .footer_authorizer {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
      }
    }
  }

  /* .background-screen {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.4);
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  } */
}

/* INPUT MODAL TO SIGN CONTRACT IN CONTRAACT DETAIL */
.inputs_to_sign {
  div {
    width: 95%;
    small {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-left: 20px;
      color: #6c6c6c;
    }
  }
}

.all_labels {
  font-size: 12px;
  display: inline-block;
  color: @color-white;
  padding: 6px;
  border-radius: 13px;
  margin-top: 3px;
  margin-bottom: 3px;
  margin-right: 3px;
}

.admin_view {
  width: 100%;
  margin: 0;
}

.show_image_identity {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
  .resize_image {
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    img {
      box-sizing: border-box;
      width: 70%;
    }
  }
}

/* FIN DE NUEVO RESPONSIVO */

/**
 * Buttons
 */
button {
  color: @color-white;
  padding: 10px 25px;
  border: none;
  border-radius: 4pt;
  font-weight: 400;
  cursor: pointer;
  outline: none;
  transition-duration: 200ms;
  -webkit-transition-duration: 200ms;
  margin: 6px 0;
  &:disabled {
    opacity: 0.5;
  }

  &:hover:not(:disabled) {
    transition-duration: 200ms;
    -webkit-transition-duration: 200ms;
  }

  &.block {
    width: 100%;
    display: block;
  }

  &.primary {
    background-color: @color-primary;
    &:hover:not(:disabled) {
      background-color: darken(@color-primary, 10%);
    }
  }

  &.neutral {
    color: @color-dark;
    background-color: @color-backdrop;

    &:hover:not(:disabled) {
      background-color: darken(@color-backdrop, 10%);
    }
  }

  &.update {
    color: #1e95ff;
    border: 1px solid #1e95ff;
    background-color: @color-white;
  }

  &.cancel {
    border: 1px solid #f95c5e;
    background-color: @color-white;
    color: #f95c5e;
    font-weight: bold;

    &.location_cd {
      grid-column: 1/3;
    }
  }
}

.color-grey {
  background: ligthen(@color-gray, 10%) !important;
  font-weight: 500;
  color: #555;
}

.placeholder input::placeholder {
  color: rgb(170, 170, 170);
}

.dropdown_tables {
  display: flex;
  justify-content: space-between;
  align-items: center;
  // background: rgba(45, 82, 218, 0.8);
  background: rgba(30, 149, 255, 0.2);
  color: black;
  padding: 10px;
  border-radius: 5px;
  margin: 10px 0;
}

.dropdown_tables i {
  font-size: 12px;
}

.dropdown-authorazer {
  -moz-appearance: none;
  appearance: none;
  padding-left: 27px;
  width: 184px;
  height: 40px;
  border-radius: 4px;
  border: solid 1px #1e95ff;
  background-color: @color-white;
}

.dropdown-authorazer #authorize {
  color: #15f0b0;
}

.dropdown-authorazer #unauthorize {
  color: #f95c5e;
}

.text_area_comments {
  border-radius: 8px;
  border: solid 1px #bfc0d2;
  background-color: @color-white;
  width: 98%;
  // margin-top: 10px;
}

.switch {
  position: relative;
  display: inline-block;
  min-width: 39px;
  height: 21px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 13px;
  width: 13px;
  left: 6px;
  bottom: 4px;
  background-color: @color-white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(15px);
  -ms-transform: translateX(15px);
  transform: translateX(15px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.content-select {
  min-width: 255px;
  position: relative;
}

.content-select select {
  appearance: none;
  display: inline-block;
  width: 100%;
  cursor: pointer;
  padding: 7px 10px;
  height: 35px;
  outline: 0;
  border: 0;
  border-radius: 0;
  background: @color-white;
  color: #292828;
  font-size: 1em;
  // color: #999;
  border: 1pt solid darken(@color-backdrop, 20%);
  border-radius: 7px;
  position: relative;
  transition: all 0.25s ease;
}

.content-select i {
  position: absolute;
  right: 10px;
  top: calc(50% - 8px);
  width: 16px;
  height: 16px;
  display: block;
}

.tooltip {
  position: relative;
  display: inline-block;
  // border-bottom: 1px dotted black;
}
.tooltip .tiptext {
  visibility: hidden;
  max-width: 300px;
  background-color: #ffdb83;
  color: black;
  font-size: 15px;
  border-radius: 3px;
  padding: 6px 2px;
  position: absolute;
  z-index: 1;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);

  .title_div {
    margin-bottom: 10px;
  }

  .tooltip_title {
    font-weight: 500;
    line-height: 1.5px;
  }

  .tooltip_info {
    color: #525252;
  }
}
/* .tooltip .tiptext::after {
  content: '';
  position: absolute;
  border-width: 5px;
  border-style: solid;
} */
.tooltip:hover .tiptext {
  visibility: visible;
}

.tooltip.top .tiptext {
  bottom: 50px;
  left: 0;
}
.tooltip.top .tiptext::after {
  top: 0;
  left: 0;
  border-color: #ffdb83 transparent transparent transparent;
}

.tooltip-1 {
  position: relative;
  display: inline-block;
}

.tooltip-1 .tiptext {
  visibility: hidden;
  width: 500px;
  background-color: #ffdb83;
  color: black;
  text-align: left;
  border-radius: 3px;
  padding: 6px 10px;
  position: absolute;
  z-index: 1;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}
.tooltip-1 .tiptext::after {
  content: '';
  position: absolute;
  border-width: 5px;
  border-style: solid;
}
.tooltip-1:hover .tiptext {
  visibility: visible;
}

.tooltip-1.right .tiptext {
  top: -135px;
  left: 110%;
}
.tooltip-1.right .tiptext::after {
  margin-top: -5px;
  top: 50%;
  right: 100%;
  border-color: transparent #2e2e2e transparent transparent;
}

.tiptext span {
  font-weight: normal;
}

.elements_sign_button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  background-color: #14cda9;
  color: #fff;
  border-radius: 4px;
  cursor: pointer;
}

.text_button_sife {
  color: 'white';
  padding: 0 30px;
  font-weight: bold;
}
.button_image {
  box-sizing: border-box;
  height: 53px !important;
}

.elements_sign_button:hover {
  transition: 0.3s ease-in;
  background-color: #0aac9d;
}

.label_contract {
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0;
}

.dropdown {
  position: relative;
  width: 100%;
}

.dropdown-content {
  display: none;
  position: absolute;
  min-width: 100%;
  overflow: auto;
  background-color: @color-white;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-radius: 4px;
  border: 1px solid rgb(180, 180, 180);
  padding-bottom: 10px;
}

.dropdown-content div {
  color: black;
  padding: 10px 0 10px 15px;
  text-decoration: none;
  display: block;
  cursor: pointer;
  font-size: 13px;
}

.dropdown-content div:hover {
  background-color: #e9e6e6;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.identityDocument {
  display: none;
}
.custom-file-upload {
  border: 1px solid @color-primary;
  display: inline-block;
  padding: 7px;
  margin: 5px;
  cursor: pointer;
  color: #5271e4;
  border-radius: 4px;
}

.main_documents {
  border: 1px solid @color-primary;
  padding-top: 20px;
  padding-bottom: 20px;
  border-radius: 10px;

  .title_identity_docs {
    margin: 0;
    padding-bottom: 10px;
    font-size: 15px;
  }

  .uploaded_identity_docs {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }

  .main_document {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 10px;
  }
}

.circulo {
  width: 30px;
  min-height: 30px;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  // background: @color-primary;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  border: 1px solid #6c6c6c;
}

.icon_id {
  font-size: 18px;
  margin-bottom: 10px;
}

.action_buttons_camera {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;
  margin-top: 30px;
}

.show_images {
  margin-top: 15px;
  text-align: center;

  .my_images_verification {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-left: 10px;
    // margin-top: 40px;

    img {
      box-sizing: border-box;
      height: 180px;
      margin: 0 auto;
      margin-top: 10px;
    }
  }

  .verification_validate_by {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;

    .renapo,
    .ine {
      display: flex;
      flex-direction: column;
      align-items: center;

      img {
        box-sizing: border-box;
        height: 70px;
        margin: 0 auto;
        margin-top: 10px;
      }

      small {
        padding-top: 10px;
      }
    }
  }
}

.title_public_info {
  color: #8c919c;
  font-size: 14px;
}

.data_public_info {
  color: #646a75;
  font-size: 14px;
  font-weight: 700;
}

.xml_button {
  border: 1px solid blue;
  padding: 5px;
  border-radius: 5px;
  text-align: center;
}

.input_search_signer {
  position: relative;
}

.input_search_signer > i {
  position: absolute;
  top: 17px;
  left: 10px;
}

.actions_signers {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row-reverse;

  .action_signer {
    text-align: center;
    border: 1px solid #1e95ff;
    padding: 7px;
    border-radius: 4px;
    margin-top: 10px;
    font-size: 14px;
    cursor: pointer;
    color: @color-primary;
    margin-left: 10px;
  }
}

.shadow_new_contract {
  box-shadow: 0px 1px 1px rgba(128, 133, 150, 0.24),
    0px 4px 2px rgba(65, 67, 91, 0.24);
}

.choose_rfc_option {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 25px;
  flex-wrap: wrap;

  div {
    display: flex;
    align-items: center;
    margin-right: 20px;
    margin-left: 20px;
  }
}

.modal-demo {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto;
}

.modal-content-demo {
  position: relative;
  width: 80%;
  // height: 80%;
  height: 80%;
  //MAYBE ESTE SE PUEDE REMOVER
  max-height: 100vh;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  //PONER EL HIDDEN
  overflow: auto;
  padding-bottom: 10px;
}

.modal-header-demo {
  // height: 60px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 10px;
}

.modal-body-demo {
  height: calc(100% - 120px);

  .participants_list {
    display: grid;
    padding-left: 10px;
    max-height: 75px;
    overflow: auto;
    small {
      font-size: 12px;
      font-weight: 400;
      color: #6c6c6c;
      margin-bottom: 0;
    }

    ul {
      font-size: 12px;
      font-weight: 400;
      color: #6c6c6c;
      margin-left: 0;
      margin-top: 0;
      text-align: left;
    }
  }
}

/* .modal-body-demo iframe {
  width: 100%;
  height: 100%;
  border: none;
} */

.modal-body-demo {
  iframe,
  object,
  canvas {
    width: 100%;
    height: 98%;
    border: none;
  }
}

//Esto se utilizaria para ajustarlo el react pdf en mobile
.modal-body-demo object,
.modal-body-demo canvas {
  max-width: 100%;
  max-height: 100%;
}

.modal-footer-demo {
  /* width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  margin-top: 40px;
  margin-bottom: 50px; */
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
}

.modal-btn-demo {
  font-size: 16px;
  color: #fff;
  background-color: #555;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
}

.modal-back-demo {
  margin-right: 10px;
}

.react-pdf__Page {
  margin: 10px;
  background-color: @color-white;
  border: 1px solid darkgrey;
  border-radius: 4px;
}

.react-pdf__Page__canvas {
  margin: 0 auto;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9998;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

@media (max-width: 600px) {
  /* Modals in view 'Mis documentos' */

  .scrollable_sidebar {
    max-height: 35vh;
  }

  .modal {
    width: 100%;
    height: 100%;
  }

  .modal-content-demo {
    width: 100%;
    height: 100%;
  }

  .modal-body-demo {
    height: calc(100% - 120px);

    .participants_list {
      grid-template-columns: 1fr;
      grid-template-areas:
        'participant_signers'
        'participant_authorizers'
        'participant_recipients';
    }
  }

  .modal-container-sign {
    .modal-sign {
      width: 100%;
      height: 100%;

      .participants_list {
        grid-template-columns: 1fr;
        grid-template-areas:
          'participant_signers'
          'participant_authorizers'
          'participant_recipients';
      }

      .react-pdf__Page__canvas {
        margin: 0 auto;
        //toma todo el ancho pero podemos hacer scrool horizontal
        width: 100% !important;
        // con este se verifica si toma todo lo alto
        height: 100% !important;
      }

      .all-page-container {
        width: 100% !important;
        margin: 0 auto;
      }

      object,
      iframe {
        width: 100%;
        height: 90vh;
      }

      .footer_modal {
        position: fixed;
        bottom: 0;
        z-index: 999;
        width: 100%;
      }

      &.confirm_contract_modal {
        object {
          max-height: 70vh;
          overflow: auto;
        }
      }

      &.contract_preview_authorizer {
        /* .react-pdf__Page__textContent {
          top: -25% !important;
        }
        object {
          width: 100%;
          max-height: 68vh;
        } */
        .footer_modal {
          button,
          select {
            width: 100% !important;
          }
          div {
            width: 100% !important;
          }
        }
      }
    }
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media (min-width: 600px) {
  .scrollable_sidebar {
    max-height: 35vh;
  }
  /* Modals in view 'Mis documentos' */

  .modal-content-demo {
    width: 100%;
    height: 100%;
  }

  .modal {
    width: 80%;
    height: auto;
  }

  .modal-container-sign {
    .modal-sign {
      width: 100%;
      height: 100%;
      .react-pdf__Page__canvas {
        margin: 0 auto;
        width: 100% !important;
        height: 100% !important;
      }

      .all-page-container {
        width: 100% !important;
        margin: 0 auto;
      }

      object,
      iframe {
        width: 100%;
        height: 90vh;
      }

      .footer_modal {
        position: fixed;
        bottom: 0;
        z-index: 999;
        width: 100%;
      }

      &.confirm_contract_modal {
        object {
          max-height: 70vh;
        }
      }

      &.contract_preview_authorizer {
        /* .react-pdf__Page__textContent {
          top: -25% !important;
        }
        object {
          width: 100%;
          max-height: 68vh;
        } */
      }
    }
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media (min-width: 768px) {
  .scrollable_sidebar {
    max-height: 35vh;
  }

  .modal-content-demo {
    width: 90%;
    height: 90%;
  }

  .modal-body-demo {
    height: calc(100% - 120px);

    .participants_list {
      grid-template-columns: 1fr 1fr;
      grid-template-areas:
        'participant_signers participant_authorizers'
        'participant_recipients .';
    }
  }

  .modal-container-sign {
    .modal-sign {
      width: 90%;
      height: 90%;

      .participants_list {
        grid-template-columns: 1fr 1fr;
        grid-template-areas:
          'participant_signers participant_authorizers'
          'participant_recipients .';
      }

      .react-pdf__Page__canvas {
        margin: 0 auto;
        width: 90% !important;
        height: 90% !important;
      }

      .all-page-container {
        width: 90% !important;
        margin: 0 auto;
      }

      object,
      iframe {
        width: 90%;
        height: 80vh;
      }

      .footer_modal {
        position: sticky !important;
        top: 100vh !important;
        bottom: auto;
      }

      &.confirm_contract_modal {
        object {
          max-height: 61vh;
        }
      }
    }
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .scrollable_sidebar {
    max-height: 35vh;
  }
  /* Modals in view 'Mis documentos' */

  .modal-content-demo {
    width: 80%;
    height: 85%;
  }

  .modal {
    width: 50%;
    height: auto;

    &.active_verify_photos {
      width: 80%;
    }

    &.active_verification_sife {
      width: 80%;
    }
  }

  .modal-body-demo {
    height: calc(100% - 120px);

    .participants_list {
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-areas: 'participant_signers participant_authorizers participant_recipients';
    }
  }

  .modal-container-sign {
    .modal-sign {
      width: 80%;
      height: auto;

      .participants_list {
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-areas: 'participant_signers participant_authorizers participant_recipients';
      }

      .react-pdf__Page__canvas {
        margin: 0 auto;
        width: 85% !important;
        height: 70% !important;
      }

      .all-page-container {
        width: 100% !important;
        margin: 0 auto;
      }

      object,
      iframe {
        width: 100%;
        height: 70vh;
      }
    }
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}

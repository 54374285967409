.container_public_info {
  display: grid;
  width: 60%;
  margin: 0 auto;
  margin-top: 10px;

  .padded {
    padding: 10px 20px;
  }

  .public_info_contract {
    margin-bottom: 20px;
    .header_public_info {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      padding-bottom: 10px;
      h3 {
        margin: 0;
        margin-right: 10px;
      }
    }

    .details_contract {
      display: grid;
      div {
        padding: 4px;
        small {
          font-size: 14px;
          line-height: 1.66;
          letter-spacing: 0.03333em;
        }
      }
    }
  }

  .public_info_signers {
    .header_info_signers {
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      padding-bottom: 10px;
      h3 {
        margin: 0;
        margin-right: 10px;
      }
    }

    .container_public_signers {
      .principal {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 10px;

        div {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          small {
            margin-right: 5px;
          }
        }
        span {
          margin-top: 3px;
          padding: 4px;
          color: #14cda9;
          border: 1px solid #14cda9;
          border-radius: 16px;
          font-size: 13px;
        }

        i {
          color: #8c919c;
        }
      }
      .public_signers {
        display: grid;
        border-bottom: 1px solid #14cda9;
        margin-top: 5px;
        div {
          padding: 4px;
          small {
            font-size: 14px;
            line-height: 1.66;
            letter-spacing: 0.03333em;
            word-break: break-word;
          }
        }
      }
    }
  }
}

/* Extra small devices (phones, 600px and down) */
@media (max-width: 600px) {
  .container_public_info {
    width: 95%;
  }
  .public_info_contract {
    .details_contract {
      grid-template-columns: 1fr;
    }
  }

  .public_info_signers {
    .public_signers {
      grid-template-columns: 1fr;
    }
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media (min-width: 600px) {
  .container_public_info {
    width: 95%;
  }
  .public_info_contract {
    .details_contract {
      grid-template-columns: 1fr;
    }
  }

  .public_info_signers {
    .public_signers {
      grid-template-columns: 1fr;
    }
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media (min-width: 768px) {
  .container_public_info {
    width: 80%;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .container_public_info {
    width: 60%;
  }
  .public_info_contract {
    .details_contract {
      grid-template-columns: 1fr 1fr;
    }
  }

  .public_info_signers {
    .public_signers {
      grid-template-columns: 1fr 1fr;
    }
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}

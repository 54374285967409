.new_container {
  width: 100%;
  min-height: 100vh;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 50px auto;
  grid-template-rows: 60px auto;
  grid-template-areas:
    'menu header'
    'menu home';
  transition: all 0.3s ease;

  /* 
  .sidebar {
    max-width: 50px;
  }

  &.active {
    grid-template-columns: 220px auto;
    .sidebar {
      max-width: 220px;
    }
  }
  */
}

.header {
  grid-area: header;
  padding: 0 20px;
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  justify-content: space-between;
  grid-template-areas: 'icon_menu user_name';

  .container_logo_sidebar {
    grid-area: icon_menu;
  }

  .container_user_name {
    grid-area: user_name;
    display: flex;
  }

  .container_logo_sidebar .button-menu {
    border: none;
    padding: 10px;
    background: none;
    cursor: pointer;
    font-size: 18px;
    border-radius: 100px;
    width: 50px;
    height: 50px;
    color: @color-primary;

    &:hover {
      background: #e7e7e7;
    }
  }

  .container_user_name a {
    color: @color-primary;
    text-decoration: none;
    font-weight: 300;

    i {
      margin-right: 10px;
    }
  }
}

.sidebar {
  grid-area: menu;
  background-color: @color-primary;
  height: 100%;
  overflow: hidden;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 999;
  width: 0;

  .logo_sidebar {
    text-align: center !important;
    .logo img {
      box-sizing: border-box;
      height: 50px;
      margin-top: 10px;
      margin-bottom: 50px;
    }
  }

  .only_nav,
  .user_representing,
  .user_admin {
    display: block;
    min-width: 600px;
    padding: 15px;
    color: @color-white;
    text-decoration: none;
    cursor: pointer;
    opacity: 0.5;
    transition-duration: 150ms;
    font-size: 15px;
    border-left: 4px solid transparent;

    &:hover,
    &:focus,
    &.active {
      opacity: 1;
      transition-duration: 150ms;
      border-color: @color-white;
      background-color: fade(@color-white, 10%);
    }
  }

  .fas {
    margin-right: 15px;
    width: 20px;
    text-align: center;
  }

  .list_representing,
  .list_admin {
    display: none;

    a {
      font-size: 13px;
      padding: 5px 0 5px 50px;

      i {
        display: none;
      }

      &:hover,
      &:focus,
      &.active {
        opacity: 1;
        transition-duration: 150ms;
      }
    }

    &.active {
      display: block;
      transition: ease 0.3s ease-out;
    }
  }

  .close_session {
    position: absolute;
    bottom: 0;
    padding: 15px;
    color: @color-white;
    cursor: pointer;
    opacity: 0.5;
    white-space: nowrap;
  }
}

.new_main {
  grid-area: home;
}

.grid_main_home {
  padding: 15px;
  width: 70%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 60px auto;
  grid-row-gap: 40px;
  grid-template-areas:
    'legal_name_home'
    'grid_home'
    'signers';
  // align-self: center;
  // justify-self: center;
}

.legal_name_home {
  grid-area: legal_name_home;
  text-align: center;
}

.grid_home {
  grid-area: grid_home;
  background-color: @color-white;
  border-radius: 10px;
  box-shadow: 0 2px 4px 0 rgba(65, 67, 91, 0.24),
    0 1px 2px 0 rgba(65, 67, 91, 0.24);
  .title_shortcut {
    border-bottom: 1px solid @color-primary;
    padding-left: 25px;
    font-weight: bold;
    color: @color-primary;
  }

  .shortcuts {
    display: grid;
    .new_document {
      grid-area: new_document;
    }
    .multiple_documents {
      grid-area: multiple_documents;
    }
    .see_my_documents {
      grid-area: see_my_documents;
    }
    /* grid-template-columns: repeat(auto-fit, minmax(min(100%, 250px), 1fr)); */
    padding: 24px 0;
    .shortcut {
      display: flex;
      flex-flow: column;
      justify-content: center;
      text-align: center;
      border-radius: 4px;
      color: darken(@color-gray, 20%);
      text-decoration: none;
      transition-duration: 200ms;
      margin-bottom: 24px;

      i {
        font-size: 40px;
        // padding-bottom: 10px;
      }

      &:hover {
        transform: scale(1.05);
        transition-duration: 200ms;
      }
    }
  }
}

.grid_representing_admin {
  display: grid;
  grid-area: signers;
  .signers_home {
    grid-area: signers_home;
  }
  .admins_home {
    grid-area: admins_home;
  }
  gap: 10px 10px;
  // grid-template-columns: repeat(auto-fit, minmax(min(100%, 250px), 1fr));
  // gap: 10px;
  .all_users_home {
    background-color: @color-white;
    border-radius: 10px;
    box-shadow: 0 2px 4px 0 rgba(65, 67, 91, 0.24),
      0 1px 2px 0 rgba(65, 67, 91, 0.24);
    padding: 5px;
    .title_list {
      padding: 0 10px;
      p {
        border-bottom: 1px solid @color-primary;
        font-weight: bold;
        color: @color-primary;
      }
    }
  }

  .users_home_list {
    max-height: 150px;
    .home_list {
      display: flex;
      padding-left: 10px;
      margin: 0;
      padding-bottom: 5px;
      color: #41435b;
      cursor: pointer;
      i {
        padding-right: 10px;
      }

      &:hover {
        color: @color-primary;
        transition: all 0.2s ease;
      }
    }
  }
}

.file-drop-zone {
  background-color: @color-backdrop;
  border: 4pt solid fade(@color-gray, 10%);
  border-radius: 4pt;
  padding: 17px 20px;
  text-align: center;
  box-sizing: border-box;
  min-height: 140px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  &.massive {
    min-height: 200px;
  }

  img,
  span {
    color: @color-gray;
    padding: 10px 0;
    line-height: 17pt;
  }
}

.empty {
  text-align: center;
  padding: 80px 0;

  h2 {
    color: @color-gray;
    font-weight: 400;
  }

  font-weight: 300;
}

.background-screen {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.4);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.label {
  height: 10px;
  width: 10px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  margin-right: 2px;
}

.mtm-10 {
  margin-top: -10px;
}

.mbm-10 {
  margin-bottom: -10px;
}

.float-right {
  float: right;
}

.align-center {
  text-align: center !important;
}

/* Extra small devices (phones, 600px and down) */
@media (max-width: 600px) {
  .new_container {
    grid-template-areas:
      'header header'
      'home home';

    &.active {
      .sidebar {
        width: 220px;
      }
    }
  }
  .grid_main_home {
    width: 90%;
    font-size: 13px;
    .icon_home {
      font-size: 30px !important;
      color: @color-primary;
    }

    .shortcuts {
      grid-template-columns: 1fr;
      grid-template-areas:
        'new_document'
        'multiple_documents'
        'see_my_documents';
    }

    .grid_representing_admin {
      grid-template-columns: 1fr;
      grid-template-areas:
        'signers_home'
        'admins_home';
    }
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media (min-width: 600px) {
  .new_container {
    grid-template-areas:
      'header header'
      'home home';

    &.active {
      .sidebar {
        width: 220px;
      }
    }
  }

  .grid_main_home {
    width: 90%;
    font-size: 14px;
    .icon_home {
      font-size: 30px !important;
      color: @color-primary;
    }
    .shortcuts {
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-areas: 'new_document multiple_documents see_my_documents';
    }
    .grid_representing_admin {
      grid-template-columns: 1fr;
      grid-template-areas:
        'signers_home'
        'admins_home';
    }
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media (min-width: 768px) {
  .new_container {
    grid-template-areas:
      'header header'
      'home home';
  }
  .grid_main_home {
    width: 90%;
    font-size: 16px;
    /* .shortcuts {
      padding: 30px 0;
    } */

    .grid_representing_admin {
      grid-template-columns: 1fr 1fr;
      grid-template-areas: 'signers_home admins_home';
    }
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .new_container {
    grid-template-areas:
      'menu header'
      'menu home';
    .sidebar {
      min-width: 50px;
      position: fixed;
    }

    &.active {
      grid-template-columns: 220px auto;
      .sidebar {
        max-width: 220px;
      }
    }
  }
  .grid_main_home {
    width: 70%;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}

.my_profile {
  max-width: 80%;
  margin: 0 auto;
  overflow: hidden;
}

.react-tabs {
  -webkit-tap-highlight-color: transparent;

  &__tab-list {
    border-bottom: 1px solid #f5f6f9 !important;
    margin: 0 0 10px;
    padding: 0;
  }

  &__tab {
    display: inline-block;
    border: 1px solid transparent !important;
    border-bottom: none !important;
    bottom: -1px;
    // position: relative;
    list-style: none;
    padding: 6px 12px;
    cursor: pointer;
    color: #6c6c6c;
    font-weight: 500;

    &--selected {
      background: #fff;
      border-color: #f5f6f9 !important;
      color: @color-primary;
      border-radius: 5px 5px 0 0;
    }

    &--disabled {
      color: #6c6c6c;
      cursor: default;
    }

    &:focus {
      outline: none;

      &:after {
        content: '';
        position: absolute;
        height: 5px;
        left: -4px;
        right: -4px;
        bottom: -5px;
        background: #fff;
      }
    }
  }

  &__tab-panel {
    display: none;

    &--selected {
      display: block;
    }
  }
}

/* FORM MY PROFILE */

.title_my_info {
  margin: 15px 0;
  color: @color-primary;
  border-bottom: 1pt solid darken(@color-backdrop, 5%);
  padding-bottom: 10px;
}

.permission_my_info {
  display: flex;
  align-items: center;
  margin: 5px 0;
  text-align: left;

  .description_permission_my_info {
    padding-left: 10px;
    p {
      margin: 0;
    }
    small {
      color: #9294a5;
    }
  }
}

.second_title_my_info {
  color: #555556;
  font-size: 12px;
}

.form_my_profile {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-areas:
    'legalName_mp'
    'legalRfc_mp'
    'email_mp'
    'profile_image_mp'
    'gestion_mp';

  .legalName_mp {
    grid-area: legalName_mp;
  }

  .legalRfc_mp {
    grid-area: legalRfc_mp;
  }

  .email_mp {
    grid-area: email_mp;
  }

  .profile_image_mp {
    grid-area: profile_image_mp;
  }

  .show_rfc_mp {
    grid-area: show_rfc_mp;
  }

  .gestion_mp {
    grid-area: gestion_mp;
  }

  align-items: center;
  gap: 0 10px;
}

.my_signers_admins {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-areas:
    'add_my_signer'
    'list_my_signer';

  .title_add_signer_admin {
    grid-area: add_my_signer;
  }

  .list_my_signers_admins {
    grid-area: list_my_signer;
  }

  .title_add_signer_admin {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    label {
      margin-right: 10px;
    }
    span {
      padding: 8px 20px;
      border: 1px solid @color-primary;
      border-radius: 5px;
      color: @color-primary;
    }
  }

  /* LIST MY SIGNERS OR ADMINS */
  .list_my_signers_admins {
    margin-top: 10px;
    overflow-x: auto;
    .table_list_my_signers_admin {
      .information_signer_admin {
        color: #555555;
        font-weight: normal;
        display: grid;
        text-align: left;
        align-items: center;
        justify-content: space-between;
        border-collapse: collapse;
        grid-template-columns:
          170px
          minmax(260px, auto)
          220px
          80px;
        gap: 0 10px;

        div {
          display: flex;
          align-items: center;
          justify-content: space-around;

          .open_document,
          .edit_my_signer {
            color: @color-primary;
          }

          .delete_my_signer {
            color: crimson;
          }
        }
      }

      .permissions_signer_admin {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-items: left;
        color: #6c6c6c;

        .single_permission {
          small {
            padding-right: 10px;
          }
        }
      }
    }
  }
}

/* MODAL SIGNERS */
.inputs_add_my_signers {
  display: grid;

  .input_my_signer {
    input {
      margin: 5px 0;
      padding: 10px 5px 10px 5px;
      width: 99%;
    }
  }
}

.list_signer_type {
  display: grid;
}

.title_modal_my_signer {
  color: @color-primary;
}

.permission_e_firma {
  margin: 15px 0;
}

.permission_signer_modal {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 5px 0;
  text-align: left;

  .description_permission {
    p {
      margin: 0;
    }
    small {
      color: #9294a5;
    }
  }
}

.third_group_permission {
  .container_input_labels_my_signer {
    flex-direction: column;
    align-items: flex-start;
    .label_contract {
      display: flex;
      align-items: center;
      width: 100%;
      padding-right: 5px;

      .input_label_signer_admin {
        width: 100%;
        margin: 0 !important;
        height: 15px;
        font-size: 13px;
      }
    }

    .labels_selected_my_signer {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      width: 100%;
      span {
        font-size: 13px;
        padding: 5px;
        border-radius: 13px;
        margin-right: 10px;
        margin-top: 2px;
        color: @color-white;
        cursor: pointer;
      }
    }
  }
}

.content-select-1 {
  width: 100%;
  position: relative;
  margin-top: 8px;
  margin-bottom: 15px;
}

.content-select-1 select {
  appearance: none;
  width: 100%;
  cursor: pointer;
  padding: 4px 12px;
  height: 35px;
  outline: 0;
  border: 0;
  border-radius: 0;
  color: #292828;
  font-size: 0.9em;
  border: 1px solid rgb(124, 120, 120);
  border-radius: 7px;
  position: relative;
  transition: all 0.25s ease;
}

.content-select-1 i {
  position: absolute;
  right: 20px;
  top: calc(50% - 8px);
  width: 16px;
  height: 16px;
  display: block;
}

.select_labels {
  background: @color-white;
  margin-top: 8px;
  border: 1px solid #d2d2d2 !important;
  height: 39px !important;
  border-radius: 4px;
}

/* END MODAL SIGNERS */

/*  START REQUEST DOCUMENTS IN PROFILE */
.menu_request_docs {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;

  .menu_request_doc {
    border: 1px solid #6c6c6c;
    color: #6c6c6c;
    min-width: 30%;
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: center;
    border-radius: 12px;
    cursor: pointer;
    margin: 3px;

    small {
      padding: 10px;
    }

    &.active_menu {
      border: 2px solid @color-primary;
      color: black;
      box-shadow: 0px 2px 8px -1px rgba(0, 0, 0, 0.75);
      -webkit-box-shadow: 0px 2px 8px -1px rgba(0, 0, 0, 0.75);
      -moz-box-shadow: 0px 2px 8px -1px rgba(0, 0, 0, 0.75);
    }
  }
}

.my_users_request_documents {
  .form_request_document {
    margin-top: 30px;
    text-align: center;
  }

  .table_request_documents {
    margin: 0 auto;
    tr {
      display: table-row;
    }
    td {
      padding: 3px;
      border: none;
    }
  }

  .add_and_finish_request {
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    color: @color-primary;
  }

  .table_request_documents,
  .add_and_finish_request {
    width: 100%;
  }
}
.list_all_users {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-areas: 'table_my_users';

  .table_list_requested_users {
    grid-area: table_my_users;
    border-collapse: collapse;
    grid-template-columns:
      minmax(150px, auto)
      minmax(200px, auto)
      minmax(220px, auto)
      minmax(80px, auto);
    gap: 0 10px;

    th,
    td {
      border: none;
    }

    .status_my_user {
      p {
        margin: 0;
      }
    }
  }
}

.table_list_requested_users {
  min-width: 100%;
  overflow-x: auto;
  margin-top: 10px;
  flex: 1;
  display: grid;
  border-collapse: collapse;
  grid-template-columns: repeat(4, minmax(1fr, 1fr));

  gap: 0 5px;
}

.dropdown_token_authorize {
  position: relative;

  select {
    border: 1px solid #afaeae;
    padding-right: 50px;
  }

  i {
    position: absolute;
    min-width: 20px;
    padding: 18px 0;
    right: 0;
    top: 0;
  }
}

.container_protected_user {
  margin: 0 auto;
  text-align: center;
}

.new_template_container {
  width: 80%;
  margin: 0 auto;
  word-break: break-all;
}

/* END REQUEST DOCUMENTS IN PROFILE */

/* Extra small devices (phones, 600px and down) */
@media (max-width: 600px) {
  .react-tabs {
    &__tab-list {
      width: 280px;
      overflow-x: auto;
      white-space: nowrap;
      margin: 0 auto;
      margin-bottom: 10px;
    }
  }

  .list_my_signers_admins {
    .permissions_signer_admin {
      display: none !important;
    }
  }

  /* MODAL SIGNERS */
  .inputs_add_my_signers {
    grid-template-columns: 1fr;
  }

  .list_signer_type {
    grid-template-columns: 1fr;
  }

  .permission_signer_modal {
    width: 100%;
  }

  .label_contract {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    padding-right: 5px;

    span {
      display: none;
    }
  }

  .label_info {
    padding-bottom: 10px;
  }

  .menu_request_doc {
    width: 100%;
  }

  .form_request_document {
    .origin_input,
    .rfc_requested {
      width: 100%;
    }
  }

  .activate_all_notifications {
    justify-content: space-between;
  }

  .form_my_profile {
    grid-template-columns: 1fr;
    grid-template-areas:
      'legalName_mp'
      'legalRfc_mp'
      'email_mp'
      'profile_image_mp'
      'gestion_mp';
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media (min-width: 600px) {
  .list_my_signers_admins {
    .permissions_signer_admin {
      display: none !important;
    }
  }

  /* MODAL SIGNERS */
  .inputs_add_my_signers {
    grid-template-columns: 1fr;
  }

  .list_signer_type {
    grid-template-columns: 1fr;
  }

  .container_protected_user {
    width: 100%;
  }

  .activate_all_notifications {
    justify-content: space-between;
  }

  .form_my_profile {
    grid-template-columns: 1fr;
    grid-template-areas:
      'legalName_mp'
      'legalRfc_mp'
      'email_mp'
      'profile_image_mp'
      'gestion_mp';
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media (min-width: 768px) {
  /* FORM MY PROFILE */
  .form_my_profile {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas:
      'legalName_mp legalRfc_mp'
      'email_mp gestion_mp'
      'profile_image_mp .';
  }

  .list_my_signers_admins {
    .permissions_signer_admin {
      display: flex !important;
    }
  }

  /* MODAL SIGNERS */
  .inputs_add_my_signers {
    grid-template-columns: 1fr 1fr 1fr;
    gap: 0 30px;
  }

  .list_signer_type {
    grid-template-columns: 50%;
  }

  .container_protected_user {
    width: 100%;
  }

  .activate_all_notifications {
    justify-content: left;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .form_request_document {
    .origin_input {
      width: 50%;
    }
  }

  .table_request_documents,
  .add_and_finish_request {
    width: 55% !important;
  }

  .container_protected_user {
    width: 40%;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  /* Modals in view 'Mis documentos' */
  .first_group_permission,
  .second_group_permission {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .permission_signer_modal {
    width: 50%;
    padding-right: 10px;
  }

  .third_group_permission {
    .container_input_labels_my_signer {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      .label_contract {
        display: flex;
        align-items: center;
        width: 50%;
      }

      .labels_selected_my_signer {
        width: 50%;
      }
    }
  }
}

/* estilo para el contenedor principal de la página */
.container_landing {
  display: grid;
  place-items: center;
  height: 90vh;
  // background-color: #f0f0f0;
}

/* estilo para el contenedor que contiene el formulario de inicio de sesión y la información adicional */
.login_wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas: 'login_form info_login_form';
  align-items: center;
  justify-content: center;
  // padding: 2rem;
}

.login_form {
  grid-area: login_form;
}

.info_login_form {
  grid-area: info_login_form;
}

/* estilo para el formulario de inicio de sesión */
.login_form {
  background: #f8f9fa;
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175);
  border-radius: 20px;
  padding: 2.5rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .inputs_login_form {
    .error_message {
      padding: 0.75rem 1.25rem;
      margin-bottom: 1rem;
      border: 1px solid transparent;
      border-radius: 0.25rem;
      color: #721c24;
      background-color: #f8d7da;
      border-color: #f5c6cb;
    }
    .success_message {
      padding: 0.75rem 1.25rem;
      margin-bottom: 1rem;
      border: 1px solid transparent;
      border-radius: 0.25rem;
      color: #155724;
      background-color: #d4edda;
      border-color: #c3e6cb;
    }
    input {
      margin-bottom: 1rem;
      background-color: @color-white;
      box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
    }

    .demo_text {
      text-align: left;
      font-weight: lighter;
      .button_send {
        font-style: italic;
        font-weight: 700;
      }
    }

    ol {
      font-weight: lighter;
      line-height: 2;
      text-align: left;
    }

    .activate_checkbox_landing {
      div {
        margin-right: 10px;
      }
    }
  }

  .start_session {
    width: 100%;
    margin-bottom: 20px;
  }

  .sife_actions {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: 1.5px solid #6cffbf;
    border-radius: 4px;

    img {
      box-sizing: border-box;
      height: 50px;
    }
  }
}

/* estilo para la información adicional sobre el inicio de sesión */
.info_login_form {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175);
  padding: 2rem;
  background: linear-gradient(#2851e5, #186ff1);
  border-radius: 15px;

  .container_info_login {
    height: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    h1,
    h2,
    h3,
    label,
    p {
      color: @color-white;
    }

    .message_register {
      display: flex;
      align-items: center;
      justify-content: space-around;

      i {
        color: @color-white;
      }

      p {
        margin-bottom: 2rem;
        color: @color-white;
        font-size: 16px;
      }
    }

    h1 {
      font-weight: bold;
      font-size: 20px;
    }
    h2 {
      font-weight: bold;
      font-size: 16px;
    }
    h3 {
      font-weight: normal;
      font-size: 14px;
    }
    label {
      font-size: 14px;
    }

    button {
      color: black;
    }
  }
}

.primary_button_landing {
  padding: 11px 22px;
  gap: 8px;
  min-height: 42px;
  font-weight: bold;
  background: linear-gradient(
    135deg,
    #4fd5d9 28.12%,
    @color-primary 100%,
    @color-primary 100%
  );
  border-radius: 8px;
  &.session {
    width: 100%;
  }
}

.secondary_button_landing {
  padding: 11px 22px;
  gap: 8px;
  width: 100%;
  min-height: 42px;
  color: black;
  background: rgba(145, 158, 171, 0.2);
  border-radius: 8px;
  font-weight: bold;
}

.sife_info {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;
  width: 100%;
  h4 {
    font-style: normal;
    font-size: 22px;
    margin: 0;
  }
}

.no_account_sife {
  margin: 20px;

  .register_link {
    color: @color-primary;
    font-size: 13px;
  }
}

.divider {
  width: 100%;
  height: 1px;
  background-color: #ccc;
  position: relative;
  margin: 40px auto;
}

.divider-text {
  position: absolute;
  top: -10px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #f8f9fa;
  padding: 0 10px;
  font-size: 16px;
  color: #999;
}

.footer_login {
  width: 100%;
}

/* Extra small devices (phones, 600px and down) */
@media (max-width: 600px) {
  .login_wrapper {
    grid-template-columns: 1fr;
  }

  .login_form {
    max-width: 320px;
  }

  .info_login_form {
    display: none;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media (min-width: 600px) {
  .login_wrapper {
    grid-template-columns: 1fr;
  }
  .login_form {
    max-width: 500px;
  }
  .info_login_form {
    display: none;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media (min-width: 768px) {
  .login_wrapper {
    grid-template-columns: 1fr 1fr;
    gap: 40px;
    padding: 2rem;

    &.register_landing {
      grid-template-areas: 'info_login_form login_form';
    }
  }
  .login_form {
    max-width: 430px;

    &.redesign {
      max-width: 600px;
      height: 400px;
    }
  }
  .info_login_form {
    display: block;
    max-width: 420px;

    &.redesign {
      max-width: 600px;
      height: 400px;
    }
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
